$(() => {
    $(".js-navbar__toggler").click((e) => {
        e.preventDefault();
        $(".js-hamburger").toggleClass('hamburger--active');
        $('body').toggleClass("nav-open");
        $(".js-navbar__collapse").stop().slideToggle();
    });

    $(".nav__mobile-dropdown").on("click", function(e){
        e.preventDefault();

        if ($(this).hasClass("nav__mobile-dropdown--active")) {
            $(this).removeClass("nav__mobile-dropdown--active");
            $(this).next(".nav__dropdown").slideUp();
        } else {
            $(this).addClass("nav__mobile-dropdown--active");
            $(this).next(".nav__dropdown").slideDown();
        }
    });

    $(document).on("click", ".js-nav-collapse", (e) => {
        $(e.currentTarget).toggleClass('expanded');
    });

    $(window).resize(() => {
        if ($(window).innerWidth > 1000) {
            $(".js-navbar__collapse").stop().show();
        }
    })

    if ($(window).width() <= 992) {
        console.log("jup");
        $(".nav__dropdown").slideUp();
    }
});
