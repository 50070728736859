const collapsedClass = "faq--collapsed";

$(function () {
    $('.faq').on('click', '.js-collapse', function () {
        collapseFaq(this);
    });

    var $faqItems = $('.js-collapse');
    if ($faqItems.length > 0) {
        $faqItems.map(function (i) {
            // initialize items
            var $item = $($faqItems[i]);
            var $content = $item.closest(".faq__item");

            if ($item.data("collapsed")) {
                $content.addClass(collapsedClass);
            } else {
                $content.removeClass(collapsedClass);
            }
        });
    }

    function collapseFaq(el) {
        var $content = $(el).closest(".faq__item");

        if ($(el).data("collapsed")) {
            $content.removeClass(collapsedClass);
            $(el).data("collapsed", false);
        } else {
            $content.addClass(collapsedClass);
            $(el).data("collapsed", true);
        }
    }
});
