const classCollapse = 'field__dropdown--collapsed';

$((event) => {
    var $dropdowns = $('[data-dropdown]');

    $(document).click(function(event) {
        if ($(event.target).closest('.js-field').find('[data-dropdown]').length === 0) {
            var $dropdown = $('[data-dropdown]');
            setDropdown($dropdown, false);
        }
    });

    // listen to click event to open dropdown
    $(document).on('click', '[data-dropdown] ~ .js-dropdown-label', function() {
        var $dropdown = $(this).siblings('[data-dropdown]');

        $('[data-dropdown]').each(function(i, e) {
            setDropdown($(this), $dropdown.get(0) == e && !$(this).data('dropdown'));
        });
    });

    $(document).on('change', '[data-dropdown] .js-checkbox', function (event) {
        var labelText,
            selectedLabels = $(this)
            .closest('[data-dropdown]')
            .find('.field__checkbox:checked')
            .closest('.field__label')
            .map(function () {

            return $(this)
                .data("text")
                .replace(/\s+/g, '')
                .trim();
        });

        var $label = $(this).closest('[data-dropdown]').siblings('.js-dropdown-label');
        if (selectedLabels.length === 0) {
            labelText = $label.data("placeholder");
        } else {
            labelText = $.makeArray(selectedLabels);
        }
        setDropdownLabel($label, labelText);
    });

    $dropdowns.each((index, value) => {
        var $dropdown = $(value);
        setDropdown($dropdown, $dropdown.data('dropdown'));
    });

    /**
     * Set dropdown value
     * @param {Element} $dropdown The dropdown to modify
     * @param {boolean} value The value to save
     */
    function setDropdown($dropdown, value) {
        $dropdown.data('dropdown', value);

        if (value) {
            $dropdown.removeClass(classCollapse);
        } else {
            $dropdown.addClass(classCollapse);
        }
    }

    /**
     * Set label text
     * @param {Element} $label The label to update
     * @param {Array} value Value of the label
     */
    function setDropdownLabel($label, value) {
        $label.find('.js-dropdown-label-span').text(value.join(', '));
    }
});
