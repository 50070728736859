
(function ($) {
    $(function () {
        if ($('#extendedFilters').length > 0) {
            // Submit handler
            $(document).on("change", "input[data-filter-extended='submit']", function (e) {
                $("input[name='ExtendedChange']").val('true');
                $(e.target.form).submit();
            });

            // Toggle handler
            $(document).on("click", "[data-filter-extended='toggle']", function (e) {
                e.preventDefault();
                $('#extendedFilters').slideToggle();
            });
        }
    });
})(jQuery);
