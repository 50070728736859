$(function () {
    if ($('#1518616682 .slider__item').length > 1) {
        const buttons = [
            '<button class="slider__button button button--ghost button--icon button--icon-only button--icon--previous"></button>',
            '<button class="slider__button button button--ghost button--icon button--icon-only button--icon--next"></button>',
        ];

        // only if more than 1 item
        $('#1518616682 .js-slider').owlCarousel({
            items: 1,
            margin: 200,
            loop: false,
            stagePadding: 0,

            nav: true,
            navContainer: "#1518616682 .slider__buttons",
            navText: buttons,
            dotsContainer: "#1518616682 .slider__dots",

            responsiveClass: true,

            autoplay: false,
            autoplayTimeout: 10000,
            autoplayHoverPause: true,
        });
    } else {
        $('#1518616682 .js-slider').removeClass("owl-carousel");
    }
});
