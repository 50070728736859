$(document).on('focusin', '.field .field__input', function (e) { // gain focus
    $(this).closest('.field').removeClass('pristine').addClass('focussed');

}).on('focusout', '.field .field__input', function (e) { // lose focus
    $(this).closest('.field').removeClass('focussed');

}).on('keyup change paste', '.field .field__input', function (e) {
    if ($(e.target).val().length > 0) {
        $(this).closest('.field').addClass('dirty');
    } else {
        $(this).closest('.field').removeClass('dirty');
    }
});

$('.field').addClass('pristine');

// Checkbox
const checkedClass = 'checked';
$(document).on('change', '.js-checkbox', function (event) {
    var $checkbox = $(event.currentTarget);
    var isChecked = $checkbox.data('checked');

    if (isChecked) {
        $checkbox.removeClass(checkedClass);
    } else {
        $checkbox.addClass(checkedClass);
    }

    $checkbox.data('checked', !isChecked);
});

$('[data-checked=true]').addClass(checkedClass);

// checkbox single item
$(document).on('change', '.js-checkbox-singleitem', function (event) {

    // one line to disable them all
    $(event.currentTarget)
        .closest('.js-field')
        .find('.js-checkbox-singleitem')
        .prop('checked', false);

    // one line to check them
    $(event.currentTarget).prop('checked', true);
});

$(document).on('change', '[data-autopostback]', function () {
    // var spinner = $(this).closest('.js-spinner-parent').find('[data-spinner]').data('spinner');
    // var event = $.Event('spinner');

    // event.spinner = spinner;

    // $(window).trigger(event);

    $(this.form).submit();
});

$(window).on('load', function () {
    $(".field .field__input").each(function () {
        var isWebkit = navigator.userAgent.search(/webkit/i) > 0;
        if ($(this).val().length > 0 || (isWebkit && $(this).is("*:-webkit-autofill"))) {
            $(this).closest('.field').addClass('dirty');
        }
    });
});
