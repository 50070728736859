function initCarSliders() {
    // reference for main items
    var slider = $('.js-carslider');
    // reference for thumbnail items
    var thumbnailSlider = $('#carslider__thumbnails');
    //transition time in ms
    var duration = 500;

    slider.each(function (i, e) {
        $(this).owlCarousel({
            loop: false,
            nav: false,
            items: 1
        }).on('changed.owl.carousel', function (e) {
            //On change of main item to trigger thumbnail item
            thumbnailSlider.trigger('to.owl.carousel', [e.item.index, duration, true]);
        });
    });
    // carousel function for main slider
    //slider.owlCarousel({
    //    loop: false,
    //    nav: false,
    //    items: 1
    //}).on('changed.owl.carousel', function (e) {
    //    //On change of main item to trigger thumbnail item
    //    thumbnailSlider.trigger('to.owl.carousel', [e.item.index, duration, true]);
    //});

    // carousel function for thumbnail slider
    thumbnailSlider.owlCarousel({
        loop: false,
        center: false, //to display the thumbnail item in center
        nav: false,
        margin: 10,
    }).on('click', '.owl-item', function () {
        // On click of thumbnail items to trigger same main item
        slider.trigger('to.owl.carousel', [$(this).index(), duration, true]);

    }).on('changed.owl.carousel', function (e) {
        // On change of thumbnail item to trigger main item
        slider.trigger('to.owl.carousel', [e.item.index, duration, true]);
    });


    //These two are navigation for main items
    $('.carslider__controls--right').click(function () {
        $(this).closest(".carslider__nav").siblings('.js-carslider').trigger('next.owl.carousel');
    });
    $('.carslider__controls--left').click(function () {
        $(this).closest(".carslider__nav").siblings('.js-carslider').trigger('prev.owl.carousel');
    });
    $(function () {
        $('.input').focusin(function () {
            input = $(this);
            input.data('place-holder-text', input.attr('placeholder'))
            input.attr('placeholder', '');
        });

        $('.input').focusout(function () {
            input = $(this);
            input.attr('placeholder', input.data('place-holder-text'));
        });
    });

    $('[data-fancybox-modal]').fancybox({
        modal: true,

        iframe: {
            css: {
                width: '1070px'
            },
            attr: {
                scrolling: "no"
            }


        },
        afterShow: function () {
            $('#close-button', $('.fancybox-iframe').contents())
                .click(function (event) {
                    $.fancybox.close(true);
                });
        }
    });
}

$(document).ready(function () {
    initCarSliders();

    if ($("#searchForm").length) {
        $(document).ajaxStop(function () {
            var searchFormTop = $("#searchForm").offset().top;
            var headerHeight = $(".navbar--fixed").outerHeight();

            $("html, body").animate({
                scrollTop: parseInt(searchFormTop - headerHeight)
            });
        });
    }
});

$(function () {
    $('input[autocomplete="off"]').disableAutofill();
});


//Show popin only once (set with sessionStorage)

$(document).ready(function () {
    if (localStorage.getItem('showNewletterPopin') !== "1") {
        setTimeout(function () {
            $('#js_triggerNewsletterPopin').trigger('click');
            localStorage.setItem('showNewletterPopin', '1');
        }, 5000);
    }
});
